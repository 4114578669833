.ratelist-form {
    text-align: center;
}
.ratelist-form-search-input {
    margin: 10px 0;
    width: 240px;
    outline: 0;
}
.parts-header{
    display: flex;
    justify-content: space-around;
    font-weight: bold;
    padding: 5px;
    border: 2px solid black;
}