.items-box {
    /* width: 99vw; */
    overflow-x: hidden;
}
.searchbar-box {
    justify-content: center;
}
.parts-box {
    display: flex;
    background: white;
    color: black;
    border: 1px solid #cccc;
    width: inherit;
    cursor: pointer;
}
.parts-box:hover {
    background: lightgray;
}
/* .items-box>div:nth-of-type(even) {
    background: #fefe;
} */
.parts-box:active {
    background: lightblue;
}
.parts-desc-box {
    display: flex;
    padding: 10px;
    width: 80%;
    flex-direction: column;
    align-items: flex-start;
    font-weight: bold;
    justify-content: center;
}
.parts-rate-box {
    display: flex;
    width: 15%;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    padding: 10px 0;
}
.parts-desc {
    color: darkviolet;
}
.parts-location {
    color: blue;
    font-weight: 12px;
}