.checkout-header {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-weight: bold;
    border-bottom: 1px solid #ccc;
}
.checkout-item-col {
    display: flex;
    flex-direction: column;
    width: 40%;
    text-align: center;
}
.checkout-item-location {
    font-weight: bold;
}
.checkout-item-rate-col {
    width: 20%;
    text-align: center;
}
.checkout-qty-col {
    width: 20%;
    text-align: center;
}
.checkout-amount-col {
    width: 20%;
    text-align: center;
}
.tbl1 {
    display:none;
}
.pdfShareBtn {
    text-align: center;
    margin-top: 10px;
}
.net-amount-box {
    text-align: end;
    padding: 10px 3%;
    font-weight: bold;
}